
class CoverVideo{
    constructor(){
        this.elems = [];

        let elems = document.querySelectorAll(".covervideo");
        elems.forEach(e => {
            let elemParent = e.parentElement;
            e.style.position = "relative";
            this.elems.push({
                elem: e,
                parent: elemParent
            });
        })

        window.addEventListener("resize", this._updatePositions.bind(this));
        this._updatePositions();
        //attempt again when font is loaded
        if(document.fonts){
            if(document.fonts.ready){
                document.fonts.ready.then(()=> {
                    this._updatePositions();
                    return;
                });
            }
        }
        //otherwise use a timeout
        setTimeout(() => {
            this._updatePositions();
        }, 1000);
    }

    _updatePositions(){
        this.elems.forEach((item, index) => {
            if(item.elem){
                let prect = item.parent.getBoundingClientRect();
                let erect = item.elem.getBoundingClientRect();
                let pratio = prect.width / prect.height;
                let eratio = erect.width / erect.height;
                let compwidth = 0;
                let compheight = 0;
                if(pratio > eratio){
                    compwidth = prect.width;
                    compheight = prect.width / eratio;
                }else{
                    compwidth = prect.height * eratio;
                    compheight = prect.height;
                }
                item.elem.style.width = compwidth + "px";
                item.elem.style.height = compheight + "px";
                item.elem.style.left = ((prect.width-compwidth)/2) + "px";
                item.elem.style.top = ((prect.height-compheight)/2) + "px";
            }
        })
    }
}
export default CoverVideo;