import "../scss/home.scss";
import Glide from '@glidejs/glide';
import BlogPaging from './components/BlogPaging.js';
import VimeoController from './components/VimeoController.js';
import CoverVideo from './components/CoverVideo.js';
const axios = require('axios');

function addGliderStyles(tarElem){
	let columns = document.getElementsByClassName("card-columns");
	let cards = document.getElementsByClassName("card");
	cards.forEach((item)=>{
		item.classList.add("glide__slide");
	});
	columns.forEach((item)=>{
		item.classList.add("glide__slides");
	});
}
function removeGliderStyles(tarElem){
	let columns = document.getElementsByClassName("card-columns");
	let cards = document.getElementsByClassName("card");
	cards.forEach((item)=>{
		item.classList.remove("glide__slide");
		item.classList.remove("glide__slide--active");
	});
	columns.forEach((item)=>{
		item.classList.remove("glide__slides");
	});
}


var services_slide;
var staff_slide;
function onWinResize(){
	let glideBreakpoint = 540;
	let winww = window.innerWidth || document.clientWidth || document.getElementsByTagName("body")[0].clientWidth;
	
	if (winww < glideBreakpoint){
		//services
		if(!services_slide){
			addGliderStyles(document.querySelectorAll(".services_slide")[0]);
			services_slide = new Glide('.services_slide', {
				hoverpause: true,
				rewind: true,
				animationDuration: 300,
				animationTimingFunc:"ease-in-out",
				type: "slider",
				breakpoints:{
					500:{
						animationDuration: 270,
					},
					400:{
						animationDuration: 250,
					}
				}
			})
			services_slide.mount();
		}
		
		//staff
		if(!staff_slide){
			addGliderStyles(document.querySelectorAll(".staff_slide")[0]);
			staff_slide = new Glide('.staff_slide', {
				hoverpause: true,
				rewind: true,
				animationDuration: 300,
				animationTimingFunc:"ease-in-out",
				type: "slider",
				breakpoints:{
					500:{
						animationDuration: 270,
					},
					400:{
						animationDuration: 250,
					}
				}
			}).mount();
			staff_slide.mount();
		}

	}else{
		//services
		if(services_slide){
			services_slide.destroy();
			services_slide = null;
			removeGliderStyles(document.querySelectorAll(".services_slide")[0]);
		}

		//staff
		if(staff_slide){
			staff_slide.destroy();
			staff_slide = null;
			removeGliderStyles(document.querySelectorAll(".staff_slide")[0]);
		}
	}
}

window.addEventListener( "load", (event) => {

	var sliderHome = new Glide('.hero_slider', {
		autoplay: 6000,
		hoverpause: true,
		rewind: true,
		animationDuration: 600,
		animationTimingFunc:"ease-in-out",
		type: "slider",
		gap: 0,
		breakpoints:{
			600:{
				animationDuration: 350,
			},
			400:{
				animationDuration: 280,
			}
		}
	}).mount();
	removeGliderStyles(document.querySelectorAll(".services_slide")[0]);
	removeGliderStyles(document.querySelectorAll(".staff_slide")[0]);
	onWinResize();

	new VimeoController();
	new CoverVideo();

	new BlogPaging(document.getElementById("loadmore"), document.getElementById("blogcontent"));
});

window.addEventListener("resize", (event) => {
	onWinResize();
});