import Player from '@vimeo/player';

class VimeoController{
	constructor(targetSelector = "html"){

		/////////////////////
		/// ENSURE UNIQUE
		/////////////////////
		this.gindex = 0;

		/////////////////////
		/// PLAY ICON TEMPLATES
		/////////////////////
		this.playIcon = "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M21 12l-18 12v-24z'/></svg>";

		/////////////////////
		/// STATUS STORAGE
		/////////////////////
		this.background = [];
		this.customBackground = [];
		this.playbutttons = [];
		this.controls = [];

		this.playingWitVolume = [];

		/////////////////////
		/// INITiALIZE ALL
		/////////////////////
		
		this.searchRoot = document.querySelectorAll(targetSelector)[0];
		this.searchRoot.querySelectorAll(".videoContainer").forEach( (element) => {
			if(element.classList.contains("bg")){
				this.parseAsBackground(element);
			}else if(element.classList.contains("localBg")){
				this.parseAsLocalBackground(element);
			}else if(element.classList.contains("playButtonSlide")){
				this.parseWithPlayButtonSlide(element);
			}else{
				//this.parseWithPlayButton(element);
				this.parseWithPlayButtonSlide(element);
			}
		})
		
		////////////////////////////
		/// Trigger listening
		////////////////////////////
		this.last_known_scroll_position = 0;
		this.ticking = false;
		window.addEventListener("scroll", (e) => {
			this.last_known_scroll_position = window.scrollY;

			if (!this.ticking) {
				window.requestAnimationFrame(function() {
					this.evaluate();
					this.ticking = false;
				}.bind(this));

				this.ticking = true;
			}
		})
	}


	evaluate(){
		this.background.forEach((item)=>{
			let dom = document.getElementById(item.elementId);
			if(!this.isVisible(dom)){
				item.pause();
			}else{
				item.getPaused().then(function(paused) {
				    item.play();
				}).catch(function(error) {
				    // an error occurred
				});
				
			}
		});
		this.controls.forEach((item)=>{
			let dom = document.getElementById(item.elementId);
			if(!this.isVisible(dom)){
				item.pause();
			}
		})
		this.playbutttons.forEach((item)=>{
			let dom = document.getElementById(item.elementId);
			if(!this.isVisible(dom)){
				item.pause();
			}
		})
	}

	//check both up/down and sides
	isVisible(el){
	    var rect = el.getBoundingClientRect();

	    return (
	        rect.top >= -rect.height &&
	        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + rect.height &&
	        rect.left >= -rect.width &&
	        rect.right <=(window.innerWidth || document.documentElement.clientWidth) + rect.width
	    );
	}

	parseAsBackground(element){
		let videoId = this.getVideoId(element);
		let elementId = "vim"+videoId+"_"+(this.gindex++).toString();
		element.setAttribute("id", elementId);
		let player = new Player(elementId, {
		    id: videoId,
		    width: "100%",
		    autopause: false,
		    background: true,
		    playsinline: true,
		    autoplay: true,
		    muted: true
		});
		player.elementId = elementId;
		this.background.push(player);
	}

	parseAsLocalBackground(element){
		let vid = document.createElement("video");
		let videourl = this.getVideoId(element).substr(4);
		vid.setAttribute("playsinline", "true");
		vid.setAttribute("muted", "true");
		vid.setAttribute("loop", "true");
		vid.setAttribute("autoplay", "true");
		vid.setAttribute("width", "380");
		vid.setAttribute("src", videourl);
		element.appendChild(vid);
		this.customBackground.push(vid);
	}

	parseWithPlayButtonSlide(element){
		let videoId = this.getVideoId(element);
		let elementId = "vim"+videoId;
		element.setAttribute("id", elementId);
		let player = new Player(elementId, {
		    id: parseInt(videoId),
		    width: "100%",
		    autopause: true,
		    controls: false
		});
		player.elementId = elementId;

		//create the overlay
		let acontainer = document.createElement("div");
		let a = document.createElement("a");
		element.appendChild(acontainer);
		acontainer.appendChild(a);
		a.setAttribute("href", "#");
		a.innerHTML = "Play";
		acontainer.classList.add("playOverlay");

		//add events for click / pause and payer listeners
		a.addEventListener("click", e => {
			console.log("click");
			acontainer.classList.add("waiting");
			player.getPaused().then(function(paused) {
			    if(paused){
			    	player.play();
			    }else{
		    		player.pause();
			    }
			}).catch(function(error) {
			    // an error occurred
			});
			e.preventDefault();
		});
		player.on('pause', function(data) {
		   acontainer.classList.remove("active");
		   acontainer.classList.remove("waiting");
		});
		player.on('play', function(data) {
		   acontainer.classList.add("active");
		   acontainer.classList.remove("waiting");
		});

		player.playButton = a;
		this.playbutttons.push(player);
	}

	parseWithPlayButton(element){
		let videoId = this.getVideoId(element);
		let elementId = "vim"+videoId;
		element.setAttribute("id", elementId);
		let player = new Player(elementId, {
		    id: parseInt(videoId),
		    width: "100%",
		    autopause: true,
		    controls: false
		});
		player.elementId = elementId;

		//create the overlay
		let a = document.createElement("a");
		element.appendChild(a);
		a.setAttribute("href", "#");
		a.classList.add("playOverlay");

		//add events for click / pause and payer listeners
		a.addEventListener("click", e => {
			console.log("click");
			player.getPaused().then(function(paused) {
			    if(paused){
			    	player.play();
			    }else{
		    		player.pause();
			    }
			}).catch(function(error) {
			    // an error occurred
			});
			e.preventDefault();
		});
		player.on('pause', function(data) {
		   a.classList.remove("active");
		});
		player.on('play', function(data) {
		   a.classList.add("active");
		});

		player.playButton = a;
		this.playbutttons.push(player);
	}

	// utils
	getVideoId(element){
		return  element.getAttribute("vimeoid");
	}
}

export default VimeoController;