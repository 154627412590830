import axios from 'axios';
import Template from '../helpers/Template.js';
import gsap from "gsap";

/**
     * 
     * @param {HTMLElement} loadbtn load more button
     * @param {HTMLElement} container container to place content
     */
class BlogPaging{

    /**
     * 
     * @param {HTMLElement} loadbtn load more button
     * @param {HTMLElement} container container to place content
     */
    constructor(loadbtn, container){
        this.nextpageurl = "/blogPaginate?page=2";
        this.container = container;
        this.btn = loadbtn;

        this.template = "<article class='col col-md-6'> \
        <div class='card-header' style='background-image:url(\"{{img}}\")'></div>\
        <div class='card-body'>\
            <h3 class='card-title'>{{titulo}}</h3>\
            <p class='card-text'>{{bajada}}</p>\
            <div class='btn-wrapper'>\
                <a href='/blog/{{slug}}' class='btn-custom'><span class='text'>seguir leyendo</span> <span class='arrow'></span></a>\
            </div>\
        </div>\
    </article>";

        this.btn.addEventListener("click", (e)=>{
            this.loadMore();
            e.preventDefault();
        })
    }

    loadMore(){
        this.btn.classList.add("loading");
        axios.get(this.nextpageurl)
        .then(function (response) {
            this.nextpageurl = response.data.next_page_url;
            this.btn.classList.remove("loading");
            if(!this.next_page_url){
                this.btn.classList.add("d-none");
            }
            this.showResults(response.data.data);
        }.bind(this))
        .catch(function (error) {
            console.log(error);
        })
    }

    showResults(data){
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            const nelem = this.stringToElem(Template(this.template, element));
            this.container.append(nelem);
            gsap.from(nelem, {y:20, autoAlpha:0, duration:0.7, delay:i*0.3});
        }
    }

    stringToElem (str){
        const div = document.createElement('div');
        div.innerHTML = str.trim();
        return div.firstChild; 
    }
}
export default BlogPaging;